import { Typography, Row, Col } from 'antd'

function Terms() {
  const textStyle = {
    color: 'white'
  };

  return (
    <Typography component='div' style={{'height': '100vh', 'overflow': 'auto'}}>
        <Row type='flex' justify='center' style={{'margin': '20px'}}>
            <Col span={20}>
                <Typography.Title level={2} style={textStyle}>
                    TERMS OF SERVICE
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    Last updated: November 20, 2024
                </Typography.Paragraph>


                <Typography.Title level={3} style={textStyle}>
                    AGREEMENT TO TERMS OF SERVICE
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and UNDS ("Company", “we”, “us”, or “our”), concerning your access to and use of the <a href='https://unds.hiappstore.com' rel="noopener noreferrer" target='_blank' style={{'color': 'blue'}}>https://unds.hiappstore.com</a> website and the UNDS mobile application (collectively, the “App”). By accessing or using the App, you acknowledge that you have read, understood, and consent to be bound by these Terms of Use. If you do not accept these terms, please refrain from using the App.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    Supplemental terms and conditions or documents that may be posted on the App from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will inform you about any changes by updating the “Last updated” date of these Terms of Use. Your continued use of the App after any such changes are posted will be considered acceptance of those changes.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    The information provided on the App is intended for global distribution and is available for download on the Apple Store. While the App is accessible worldwide, it is not intended for use in any jurisdiction or country where such use would be contrary to local law or regulation, or which would subject us to any registration requirement within such jurisdiction or country. We encourage those who access the App from various locations to ensure they do so in compliance with their local laws, to the extent that these laws are applicable.
                </Typography.Paragraph>

                <Typography.Title level={3} style={textStyle}>
                    INTELLECTUAL PROPERTY RIGHTS
                </Typography.Title>

                <Typography.Paragraph style={{ color: 'red' }} style={textStyle}>
                    This App functions as an emulator. It is your responsibility to ensure that any ROMs you import and use within the app are legally owned or properly authorized for use. Unauthorized use of copyrighted materials, including ROMs, is strictly prohibited and may result in legal action. If you have any copyright concerns or questions, please contact us directly.
                </Typography.Paragraph>

                <Typography.Title level={3} style={textStyle}>
                    USER REPRESENTATIONS
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    By using the Site, you represent and warrant that:
                </Typography.Paragraph>


                <Typography component='div' style={{'marginLeft': '40px'}} style={textStyle}>
                <Typography.Paragraph style={textStyle}>
                    (1) you have the legal capacity and you agree to comply with these Terms of Use;
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (2) you are not a minor in the jurisdiction in which you reside;
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (3) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise;
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (4) you will not use the Site for any illegal or unauthorized purpose; and
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (5) your use of the Site will not violate any applicable law or regulation.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (6) Ensure you only use game ROMs that you legally own. Downloading and using unauthorized ROM files is illegal.
                </Typography.Paragraph>
                
                </Typography>



                <Typography.Paragraph style={textStyle}>
                    If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).
                </Typography.Paragraph>

                <Typography.Title level={3} style={textStyle}>
                    PROHIBITED ACTIVITIES
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    You may not access or use the App for any purpose other than that for which we make the App available. The App may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us. Prohibited activity includes, but is not limited to:
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    - Attempting to bypass any measures of the App designed to prevent or restrict access to the App, or any portion of the App.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    - Using the App to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit, or other malicious computer software.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    - Conducting any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to the App without our express written consent.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    - Using the App in a way that is intended to harm, or a reasonable person would understand would likely result in harm, to the user or others.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    - Engaging in any activity that interferes with or disrupts the App's performance, or that disrupts or manipulates the user experience.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    - Using the App to engage in any unlawful activities, including but not limited to fraud, privacy invasion, infringement of intellectual property rights, or other activities that violate the rights of any third party or are otherwise prohibited by law.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    - Importing or using ROMs in the App without proper authorization or in violation of intellectual property laws.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    You are required to comply with all applicable laws and regulations in your use of the App, including those pertaining to intellectual property rights when using ROMs.
                </Typography.Paragraph>
                    
                <Typography.Title level={3} style={textStyle}>
                    SUBMISSIONS
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site ("Submissions") provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you. You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.
                </Typography.Paragraph>

                <Typography.Title level={3} style={textStyle}>
                    SITE MANAGEMENT
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    We reserve the right, but not the obligation, to:
                </Typography.Paragraph>

                <Typography component='div' style={{'marginLeft': '40px'}} style={textStyle}>
                <Typography.Paragraph style={textStyle}>
                    (1) monitor the Site for violations of these Terms of Use;
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities;
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.
                </Typography.Paragraph>

                </Typography>

                <Typography.Title level={3} style={textStyle}>
                    TERM AND TERMINATION
                </Typography.Title>


                <Typography.Paragraph style={textStyle}>
                    These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
                </Typography.Paragraph>


                <Typography.Title level={3} style={textStyle}>
                    MODIFICATIONS AND INTERRUPTIONS
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.
                </Typography.Paragraph>


                <Typography.Title level={3} style={textStyle}>
                    CORRECTIONS
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
                </Typography.Paragraph>
                
                
                <Typography.Title level={3} style={textStyle}>
                    DISCLAIMER
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    The information, software, products, and services included in or available through the App and the website may include inaccuracies or typographical errors. Changes are periodically added to the information herein. UNDS and/or its suppliers may make improvements and/or changes in the App or website at any time.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    UNDS does not represent or warrant that the App or website will be uninterrupted or error-free, that defects will be corrected, or that the App, the website, or the server that makes them available are free of viruses or other harmful components. UNDS does not warrant or represent that the use or the results of the use of the App or the website or the materials made available as part of the App or website will be correct, accurate, timely, or otherwise reliable.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    UNDS specifically disclaims any liability for unauthorized use of ROMs and other copyrighted materials within the App. Users are solely responsible for ensuring that their use of the App complies with all applicable intellectual property laws.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    UNDS shall not be responsible for unauthorized access to or alteration of your transmissions or data, any material or data sent or received or not sent or received, or any transactions entered into through the App or website.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    UNDS is not responsible or liable for any threatening, defamatory, obscene, offensive, or illegal content or conduct of any other party or any infringement of another's rights, including intellectual property rights.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    If you are dissatisfied with the App or the website, your sole and exclusive remedy is to discontinue using them.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    The above disclaimer applies to any damages, liability or injuries caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction of or unauthorized access to, alteration of, or use, whether for breach of contract, tort, negligence, or any other cause of action.
                </Typography.Paragraph>



                 <Typography.Title level={3} style={textStyle}>
                    LIMITATIONS OF LIABILITY
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    As an independent developer, our resources are limited, and we must limit our liability accordingly. IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE APP, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US FOR THE APP OR FIFTY DOLLARS ($50). CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                </Typography.Paragraph>


                <Typography.Title level={3} style={textStyle}>
                    INDEMNIFICATION
                </Typography.Title>


                <Typography.Paragraph style={textStyle}>
                    You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of:
                </Typography.Paragraph>

                <Typography component='div' style={{'marginLeft': '40px'}} style={textStyle}>

                <Typography.Paragraph style={textStyle}>
                    (1) use of the Site;
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (2) breach of these Terms of Use;
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (3) any breach of your representations and warranties set forth in these Terms of Use;
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    (5) any overt harmful act toward any other user of the Site with whom you connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
                </Typography.Paragraph>

                </Typography>
                
                <Typography.Title level={3} style={textStyle}>
                    USER DATA
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    We will maintain certain data that you transmit to the App for the purpose of managing the performance of the App, as well as data relating to your use of the App. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the App. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    For users within the European Union, we adhere to the General Data Protection Regulation (GDPR). Under GDPR, you have the right to access, rectify, port, and erase your data. You also have the right to object to and restrict certain processing of your data. This includes the right to opt out of data processing based on legitimate interests, direct marketing, and processing for research or statistical purposes. To exercise these rights, please contact us directly through the contact information provided on our App.
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    We take the protection of your data seriously and implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, protecting your data against unauthorized or unlawful processing and against accidental loss, destruction, or damage.
                </Typography.Paragraph>


                <Typography.Title level={3} style={textStyle}>
                    MISCELLANEOUS
                </Typography.Title>


                <Typography.Paragraph style={textStyle}>
                    These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.
                </Typography.Paragraph>

            </Col>
        </Row>
    </Typography>
  )
}

export default Terms
