import React, { useContext, useState } from 'react';
import { Typography, Collapse, Layout, Affix, Row, Col, Image, Button, Input, Space, Rate, message } from 'antd';
import { AppleOutlined, QuestionCircleOutlined, SmileOutlined, MehOutlined } from '@ant-design/icons';
import { Context } from './store/Context';

const { Title, Paragraph, Link } = Typography;
const { Panel } = Collapse;
const { Search } = Input;

function getLanguage() {
  return navigator.language || navigator.userLanguage;
}

function isChineseLang(lang) {
  return lang.startsWith('zh');
}

const FAQ = () => {
  const { state } = useContext(Context);
  const lang = getLanguage();
  const isChinese = isChineseLang(lang);
  const [searchText, setSearchText] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackRating, setFeedbackRating] = useState(0);

  const handleFeedbackSubmit = () => {
    message.success(isChinese ? '感谢您的反馈！' : 'Thank you for your feedback!');
    setFeedbackSubmitted(true);
  };

  const faqData = {
    zh: [
      {
        question: '如何安装模拟器?',
        answer: '您可以在苹果应用商店搜索并下载我们的模拟器应用。安装过程与其他iOS应用相同,请按照提示完成即可。为了获得最佳性能,建议您在较新的iOS设备上运行模拟器。',
      },
      {
        question: '模拟器支持哪些游戏?',
        answer: '我们的模拟器支持Game Boy、Game Boy Color、Game Boy Advance和Nintendo DS等多个经典掌机平台的游戏。您可以在模拟器中玩到数以千计的怀旧游戏,重温儿时的美好回忆。不过请注意,部分游戏可能存在兼容性问题,我们会在后续版本中不断改进。',
      },
      {
        question: '在哪里可以找到游戏ROM?',
        answer: '由于版权原因,我们不提供游戏ROM文件。您需要自行从合法渠道获取游戏ROM,并确保您拥有相应的游戏拷贝。请支持正版游戏,尊重游戏开发者的劳动成果。',
      },
      {
        question: '如何将游戏ROM导入模拟器?',
        answer: '您可以通过iOS设备上的"文件"应用,将游戏ROM文件复制到模拟器的ROM目录中。然后在模拟器的游戏列表中就可以看到导入的游戏了。如果需要备份游戏存档,建议直接备份整个ROM目录,以免数据丢失。',
      },
      {
        question: '如何使用金手指(作弊码)?',
        answer: '我们的模拟器内置了金手指功能。您可以在游戏过程中点击菜单按钮,选择"金手指"选项,然后输入相应的作弊码即可生效。不过请注意,使用金手指可能会影响游戏体验,请谨慎使用。',
      },
      {
        question: '如何快速存档和读档?',
        answer: '在游戏过程中,您可以随时点击菜单按钮,然后选择"保存状态"来创建一个即时存档。在需要读档时,再次点击菜单按钮,选择之前保存的存档即可恢复游戏进度。请养成定期存档的习惯,以免游戏数据丢失。',
      },
      {
        question: '如何获得帮助和支持?',
        answer: '如果您在使用过程中遇到任何问题或需要帮助,欢迎访问我们的联系页面,通过QQ群或Telegram群与我们取得联系。我们尽快回复您的咨询。',
      },
    ],
    en: [
      {
        question: 'How to install the emulator?',
        answer: 'You can search and download our emulator app from the Apple App Store. The installation process is the same as other iOS apps, please follow the prompts to complete it. For best performance, it is recommended to run the emulator on newer iOS devices.',
      },
      {
        question: 'What games does the emulator support?',
        answer: 'Our emulator supports games from multiple classic handheld platforms such as Game Boy, Game Boy Color, Game Boy Advance, and Nintendo DS. You can play thousands of nostalgic games in the emulator and relive your childhood memories. However, please note that some games may have compatibility issues, and we will continue to improve in subsequent versions.',
      },
      {
        question: 'Where can I find game ROMs?',
        answer: 'Due to copyright reasons, we do not provide game ROM files. You need to obtain game ROMs from legal sources on your own and ensure that you own the corresponding game copies. Please support genuine games and respect the hard work of game developers.',
      },
      {
        question: 'How to import game ROMs into the emulator?',
        answer: 'You can copy the game ROM files to the ROM directory of the emulator through the "Files" app on your iOS device. Then you can see the imported games in the game list of the emulator. If you need to backup game saves, it is recommended to directly backup the entire ROM directory to avoid data loss.',
      },
      {
        question: 'How to use cheat codes?',
        answer: 'Our emulator has a built-in cheat code feature. You can click the menu button during the game, select the "Cheat Codes" option, and then enter the corresponding cheat codes to take effect. However, please note that using cheat codes may affect the gaming experience, so use them with caution.',
      },
      {
        question: 'How to quickly save and load game progress?',
        answer: 'During the game, you can click the menu button at any time and then select "Save State" to create an instant save. When you need to load the game, click the menu button again and select the previously saved state to restore the game progress. Please develop the habit of saving regularly to avoid losing game data.',
      },
      {
        question: 'How to get help and support?',
        answer: 'If you encounter any problems or need help during use, please visit our contact page and get in touch with us through QQ group or Telegram group. Our customer service staff will reply to your inquiries as soon as possible during working hours. You can also search for problems or post for help on our official forum to communicate with other users.',
      },
    ],
  };

  const filteredFaqData = (isChinese ? faqData.zh : faqData.en).filter(item => 
    item.question.toLowerCase().includes(searchText.toLowerCase()) ||
    item.answer.toLowerCase().includes(searchText.toLowerCase())
  );

  const renderFeedback = () => (
    <div style={{ 
      textAlign: 'center', 
      marginTop: '20px',
      padding: '15px',
      borderTop: '1px solid #f0f0f0'
    }}>
      <Space direction="vertical" size="middle">
        {!feedbackSubmitted ? (
          <>
            <Paragraph>
              {isChinese ? '这个回答对您有帮助吗？' : 'Was this helpful?'}
            </Paragraph>
            <Space direction="vertical" size="small">
              <Rate 
                character={({ index }) => index < feedbackRating ? <SmileOutlined /> : <MehOutlined />}
                onChange={setFeedbackRating}
                count={3}
                style={{ fontSize: '20px' }}
              />
              <Button 
                type="primary"
                size="small"
                onClick={handleFeedbackSubmit}
                disabled={!feedbackRating}
              >
                {isChinese ? '提交反馈' : 'Submit Feedback'}
              </Button>
            </Space>
          </>
        ) : (
          <Paragraph type="success">
            <SmileOutlined style={{ marginRight: '8px' }} />
            {isChinese ? '感谢您的反馈！' : 'Thank you for your feedback!'}
          </Paragraph>
        )}
      </Space>
    </div>
  );

  const renderSearchResults = () => (
    <div style={{ 
      background: 'white',
      borderRadius: '10px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
    }}>
      {filteredFaqData.length > 0 ? (
        <>
          <Collapse bordered={false} defaultActiveKey={['0']}>
            {filteredFaqData.map((item, index) => (
              <Panel 
                header={item.question} 
                key={index} 
                style={{ borderBottom: index < filteredFaqData.length - 1 ? '1px solid #f0f0f0' : 'none' }}
              >
                <Paragraph>{item.answer}</Paragraph>
              </Panel>
            ))}
          </Collapse>
          <div style={{ padding: '20px' }}>
            {renderFeedback()}
          </div>
        </>
      ) : (
        <div style={{ padding: '30px' }}>
          <Paragraph style={{ textAlign: 'center' }}>
            {isChinese ? '没有找到相关问题' : 'No matching questions found'}
          </Paragraph>
          {renderFeedback()}
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Link href="/contact">
              <Button type="link" icon={<QuestionCircleOutlined />}>
                {isChinese ? '联系客服寻求帮助' : 'Contact Support for Help'}
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Layout style={{ minWidth: '100vw', minHeight: '100vh', background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)' }}>
      <Affix offsetTop={0}>
        <Layout.Header style={{ background: 'rgba(255, 255, 255, 0.8)', height: '70px', backdropFilter: 'blur(10px)' }}>
          <Row justify="space-between" align="middle" style={{ height: '100%' }}>
            <Col style={{ cursor: 'pointer' }} onClick={() => window.scrollTo(0, 0)}>
              <Image height={30} preview={false} src={state.appLogo} />
              <Typography.Title level={3} style={{ margin: 0, display: 'inline-block', marginLeft: '10px' }}>{state.appName}</Typography.Title>
            </Col>
          </Row>
        </Layout.Header>
      </Affix>

      <Layout.Content style={{ padding: '40px 20px' }}>
        <Row justify="center">
          <Col xs={24} sm={20} md={16} lg={12}>
            <Title level={2} style={{ textAlign: 'center', marginBottom: '40px' }}>{isChinese ? '常见问题' : 'FAQ'}</Title>

            <div style={{ marginBottom: '20px' }}>
              <Search
                placeholder={isChinese ? '搜索问题或答案...' : 'Search questions or answers...'}
                allowClear
                size="large"
                onChange={e => setSearchText(e.target.value)}
                style={{ width: '100%' }}
              />
            </div>

            <div style={{ textAlign: 'center', marginBottom: '40px' }}>
              <Link href="https://apps.apple.com/app/id6737550290" target="_blank">
                <Button type="primary" size="large" icon={<AppleOutlined />}>
                  {isChinese ? '从 App Store 下载' : 'Download on the App Store'}
                </Button>
              </Link>
            </div>

            {renderSearchResults()}

            <div style={{ textAlign: 'center', marginTop: '40px' }}>
              <Link href="/contact">
                <Button icon={<QuestionCircleOutlined />}>
                  {isChinese ? '联系我们' : 'Contact Us'}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Layout.Content>

      <Layout.Footer style={{ textAlign: 'center', background: 'transparent' }}>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {state.appName} © {new Date().getFullYear()}
        </Typography.Text>
      </Layout.Footer>
    </Layout>
  );
};

export default FAQ;
