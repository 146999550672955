// @Mobile.js
import {
    Typography,
    Layout,
    Row,
    Col,
    Affix,
    Image,
    Button,
    Tooltip,
    Popover,
    Card,
    Rate,
    Tag,
    Divider,
    Space,
    Avatar
} from 'antd'

import {
    DownloadOutlined,
    StarFilled,
    CheckCircleFilled,
    AppstoreOutlined,
    SafetyCertificateOutlined,
    CustomerServiceOutlined,
    GiftOutlined,
    StarOutlined,
    SnowOutlined,
    BellOutlined,
    HeartOutlined,
    MoonOutlined,
    BulbOutlined,
    BulbFilled
} from '@ant-design/icons'

import { QqOutlined, SendOutlined, CopyOutlined, HomeOutlined, ArrowRightOutlined } from '@ant-design/icons';

import {
    motion,
} from 'framer-motion'

import {
    useContext,
    useState
} from 'react'

import { Context } from './store/Context'

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function getLanguage() {
    const lang = navigator.language || navigator.userLanguage;
    // 更精确的语言判断
    if (lang.startsWith('zh-CN')) {
        return 'zh-CN'; // 简体中文
    } else if (lang.startsWith('zh-TW') || lang.startsWith('zh-HK')) {
        return 'zh-TW'; // 繁体中文
    }
    return 'en'; // 默认英文
}

function isChineseLang(lang) {
    return lang.startsWith('zh-CN') || lang.startsWith('zh-Hans');
}

// props: sectionItem, backgroundColor
function SectionItem(props) {
    return (
        <Row justify='center' align='top' style={{
            background: props.backgroundColor,
            padding: '48px 16px'
        }}>
            <Col span={24} style={{maxWidth: '680px'}}>
                <motion.div 
                    initial={{y: 20, opacity: 0}} 
                    whileInView={{y: 0, opacity: 1}}
                    transition={{duration: 0.6}}
                    viewport={{once: true}}
                >
                    <Typography.Title level={2} style={{
                        fontSize: '28px',
                        marginBottom: '16px',
                        textAlign: 'center'
                    }}>
                        {props.sectionItem.title}
                    </Typography.Title>
                    <Typography.Paragraph style={{
                        fontSize: '17px',
                        color: '#666',
                        textAlign: 'center',
                        marginBottom: '32px'
                    }}>
                        {props.sectionItem.text}
                    </Typography.Paragraph>
                    <Image 
                        preview={false} 
                        src={props.sectionItem.image} 
                        style={{
                            width: '100%',
                            borderRadius: '12px',
                            boxShadow: '0 1px 5px rgba(0,0,0,0.1)'
                        }}
                    />
                </motion.div>
            </Col>
        </Row>
    )
}

// props: sectionList
function SectionList(props) {
    return (
        <>
        {
            props.sectionList.map((sectionItem, index) => {
                return (
                    <SectionItem sectionItem={sectionItem} backgroundColor={index % 2 === 0? 'white': null} />    
                )

            })
        }
        </>
    )
}

// 更新 RetroUI 主题配置 - 现代黑金主题
const retroTheme = {
  colors: {
    primary: {
      50: "#0A0A0B",  // 更深的背景色
      100: "#111113",
      200: "#16161A",
      300: "#1C1C21",
      400: "#222228",
      500: "#FFD700", // 金色主色调
      600: "#F4C430",
      700: "#E6B800",
      800: "#D4AF37",
      900: "#CCA300",
    },
    // 更新为高级黑金配色
    text: "#FFFFFF",
    textSecondary: "#9999A9",
    textMuted: "#666670",
    surface: "#0F0F12",
    surfaceSecondary: "#16161A",
    surfaceElevated: "#1C1C21",
    border: "#FFD700", // 金色边框
    warning: "#FFD700",
    primaryLight: "#FFF1B8",
    background: "#0A0A0B",
    // 更新霓虹效果
    neon: {
      primary: "#FFD700",
      shadow: "0 0 10px rgba(255,215,0,0.3), 0 0 20px rgba(255,215,0,0.2), 0 0 30px rgba(255,215,0,0.1)",
    }
  },
  shadows: {
    xs: "1px 1px 0 0 rgba(255,215,0,0.3)",
    sm: "2px 2px 0 0 rgba(255,215,0,0.3)",
    md: "3px 3px 0 0 rgba(255,215,0,0.3)", 
    lg: "4px 4px 0 0 rgba(255,215,0,0.3)",
    "3xl": "6px 6px 0 0 rgba(255,215,0,0.3)",
    neon: "0 0 15px rgba(255,215,0,0.15)"
  },
  gradients: {
    primary: "linear-gradient(135deg, rgba(255,215,0,0.15) 0%, rgba(255,215,0,0.05) 100%)",
    surface: "linear-gradient(180deg, #0A0A0B 0%, #111113 100%)",
    header: "linear-gradient(to right, rgba(255,215,0,0.1) 0%, rgba(255,215,0,0.05) 100%)"
  }
}

// 更新 FeatureCard 组件
function FeatureCard({ icon, title, description }) {
  return (
    <Card 
      bordered={false}
      style={{
        background: retroTheme.colors.surfaceSecondary,
        border: `2px solid ${retroTheme.colors.border}`,
        borderRadius: '0px',
        padding: '24px',
        height: '100%',
        boxShadow: retroTheme.shadows.md,
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        '&:hover': {
          transform: 'translate(-2px, -2px)',
          boxShadow: `${retroTheme.shadows.lg}, ${retroTheme.shadows.neon}`,
        }
      }}
    >
      <Space direction="vertical" size={16}>
        <div style={{
          width: '48px',
          height: '48px',
          borderRadius: '0px',
          border: `2px solid ${retroTheme.colors.border}`,
          background: retroTheme.colors.surfaceElevated,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '24px',
          boxShadow: retroTheme.shadows.xs,
          color: retroTheme.colors.primary[500]
        }}>
          {icon}
        </div>
        <Typography.Title level={4} style={{
          margin: 0,
          color: retroTheme.colors.text,
          fontSize: '20px',
          fontWeight: '700',
          fontFamily: 'Press Start 2P, cursive',
          textShadow: '2px 2px 0 #000'
        }}>
          {title}
        </Typography.Title>
        <Typography.Text style={{
          color: retroTheme.colors.textSecondary,
          fontSize: '16px',
          lineHeight: 1.6
        }}>
          {description}
        </Typography.Text>
      </Space>
    </Card>
  )
}

// 更新主按钮样式
const RetroButton = ({ children, onClick, type = "primary" }) => (
  <Button
    onClick={onClick}
    style={{
      background: type === "primary" ? 
        "linear-gradient(135deg, rgba(255,215,0,1) 0%, rgba(255,215,0,0.9) 100%)" : 
        "linear-gradient(135deg, rgba(28,28,33,0.9) 0%, rgba(22,22,26,1) 100%)",
      border: `1px solid ${type === "primary" ? retroTheme.colors.border : 'rgba(255,215,0,0.3)'}`,
      borderRadius: '4px',
      height: '36px',
      padding: '0 16px',
      fontWeight: '600',
      color: type === "primary" ? '#000000' : retroTheme.colors.text,
      fontFamily: 'Inter, sans-serif',
      fontSize: '13px',
      boxShadow: `${retroTheme.shadows.sm}, ${retroTheme.shadows.neon}`,
      transition: 'all 0.2s ease',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      backdropFilter: 'blur(10px)',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: `${retroTheme.shadows.md}, ${retroTheme.shadows.neon}`,
        filter: 'brightness(1.1)'
      }
    }}
  >
    {children}
  </Button>
)

// 添加全局动画效果
const glowAnimation = {
  '@keyframes glow': {
    '0%': { boxShadow: '0 0 5px rgba(255,107,107,0.5)' },
    '50%': { boxShadow: '0 0 20px rgba(255,107,107,0.8)' },
    '100%': { boxShadow: '0 0 5px rgba(255,107,107,0.5)' }
  }
}

// 更新文案内容
const promotionalText = {
    tag: 'New Release',  // 更简洁的标签
    title: 'Experience Gaming',  // 更简洁有力的标题
    subtitle: 'Seamless gaming experience on your device', // 更新副标题
    button: 'Get Started', // 更现代的按钮文案
    gift: 'Try Premium Features' // 更简洁的促销文案
};

// 更新 Feature 部分的标题和描述
const featureTitles = {
    core: 'FEATURES',  // 更简洁的特性标题
    mainTitle: 'Powerful Experience', // 更新主标题
    subTitle: 'Designed for modern gaming', // 更新副标题
    features: [
        {
            title: 'High Performance',
            description: 'Optimized for smooth gameplay'
        },
        {
            title: 'Cloud Save',
            description: 'Your progress, anywhere, anytime'
        },
        {
            title: 'Game Boost',
            description: 'Enhanced gaming experience'
        },
        {
            title: 'Multi Platform',
            description: 'Seamless cross-platform support'
        }
    ]
};

// 更新 Policy 部分的标题
const policyTitles = {
    main: 'Legal',  // 更简洁的标题
    items: {
        payment: 'Payment Terms',
        privacy: 'Privacy Notice',
        terms: 'Terms of Use'
    }
};

// 更新社区部分的标题
const communityTitles = {
    main: 'Community',
    items: {
        qq: 'QQ Group',
        telegram: 'Telegram'
    }
};

function Mobile() {
    const {state, dispatch} = useContext(Context)

    return (
        <Layout style={{
            background: '#FFFEF0',
            fontFamily: 'var(--font-sans)'
        }}>
            <Affix offsetTop={0}>
                <Layout.Header style={{
                    background: retroTheme.colors.surfaceSecondary,
                    borderBottom: `1px solid ${retroTheme.colors.border}`,
                    padding: '0 16px',
                    height: '64px',
                    boxShadow: retroTheme.shadows.md,
                    backdropFilter: 'blur(10px)',
                    WebkitBackdropFilter: 'blur(10px)'
                }}>
                    <Row justify='space-between' align='middle' style={{width: '100%'}}>
                        <Col>
                            <Typography.Title level={4} style={{
                                margin: 0,
                                fontSize: '22px',
                                background: 'linear-gradient(45deg, #FFD700, #FFA500)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                fontWeight: '700',
                                letterSpacing: '-0.02em',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}>
                                {state.appName}
                            </Typography.Title>
                        </Col>
                        <Col>
                            <a href="/contact" target='_blank' rel="noopener noreferrer">
                                <RetroButton type="secondary">
                                    <Space>
                                        <CustomerServiceOutlined style={{color: retroTheme.colors.primary[500]}} />
                                        <span style={{color: retroTheme.colors.primary[500]}}>Contact</span>
                                    </Space>
                                </RetroButton>
                            </a>
                        </Col>
                    </Row>
                </Layout.Header>
            </Affix>

            {/* 主内容区域样式 */}
            <Layout.Content style={{
                background: retroTheme.colors.primary[50],
                minHeight: '100vh',
                marginTop: '-64px'
            }}>
                {/* Promotional Banner */}
                <Row justify='center' align='middle' style={{
                    padding: '14px 24px',
                    background: `linear-gradient(135deg, ${retroTheme.colors.primary[500]} 0%, ${retroTheme.colors.primary[700]} 100%)`,
                    position: 'relative',
                    overflow: 'hidden',
                    marginTop: '64px'
                }}>
                    {/* 装饰性背景元素 */}
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: `
                            radial-gradient(circle at 20% 20%, rgba(255,255,255,0.1) 0%, transparent 50%),
                            radial-gradient(circle at 80% 80%, rgba(255,255,255,0.05) 0%, transparent 50%)
                        `,
                        pointerEvents: 'none'
                    }}/>
                    
                    <Col>
                        <Space align="center" size={12}>
                            <GiftOutlined style={{ 
                                color: '#fff',
                                fontSize: '18px',
                                filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))'
                            }} />
                            <Typography.Text style={{ 
                                color: '#fff',
                                margin: 0,
                                fontSize: '15px',
                                fontWeight: '500',
                                textShadow: '0 1px 2px rgba(0,0,0,0.1)'
                            }}>
                                {promotionalText.gift}
                            </Typography.Text>
                            <Button 
                                type="text"
                                size="small"
                                onClick={() => window.open(state.appleStoreLink, '_blank')}
                                style={{
                                    color: '#fff',
                                    fontWeight: '500',
                                    padding: '4px 12px',
                                    background: 'rgba(255,255,255,0.2)',
                                    borderRadius: '99px',
                                    backdropFilter: 'blur(4px)',
                                    WebkitBackdropFilter: 'blur(4px)',
                                    border: '1px solid rgba(255,255,255,0.3)',
                                    transition: 'all 0.3s ease',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '4px',
                                    '&:hover': {
                                        background: 'rgba(255,255,255,0.3)',
                                        transform: 'translateY(-1px)'
                                    }
                                }}
                            >
                                <span>{promotionalText.button}</span>
                                <ArrowRightOutlined style={{ fontSize: '12px' }}/>
                            </Button>
                        </Space>
                    </Col>
                </Row>

                {/* cover headline */}
                <Row justify='center' align='top' style={{
                    padding: '80px 24px 64px',
                    background: retroTheme.gradients.surface,
                    position: 'relative',
                    overflow: 'hidden'
                }}>
                    {/* 背景装饰 */}
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: `
                            radial-gradient(circle at 0% 0%, rgba(255,215,0,0.03) 0%, transparent 50%),
                            radial-gradient(circle at 100% 100%, rgba(255,215,0,0.03) 0%, transparent 50%)
                        `,
                        opacity: 0.6
                    }}/>
                    
                    <Col span={24} style={{maxWidth: '680px', position: 'relative'}}>
                        <Row gutter={[0, 48]}>
                            <Col span={24} style={{textAlign: 'center'}}>
                                <Space direction="vertical" size={24} style={{width: '100%'}}>
                                    {/* Logo */}
                                    <div style={{marginBottom: 8}}>
                                        <Image 
                                            width={88}
                                            height={88}
                                            preview={false} 
                                            src={state.appLogo}
                                            style={{
                                                borderRadius: '22px',
                                                objectFit: 'cover',
                                                background: retroTheme.colors.surfaceSecondary,
                                                border: '1px solid rgba(255,215,0,0.08)',
                                                boxShadow: '0 2px 20px rgba(0,0,0,0.2)'
                                            }}
                                        />
                                    </div>
                                    
                                    {/* App Name */}
                                    <Typography.Title level={1} style={{
                                        fontSize: '44px',
                                        lineHeight: 1.1,
                                        margin: 0,
                                        background: 'linear-gradient(to right, #FFFFFF 30%, rgba(255,255,255,0.8))',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        fontWeight: '600',
                                        letterSpacing: '-0.02em'
                                    }}>
                                        {state.appName}
                                    </Typography.Title>

                                    {/* Description */}
                                    <Typography.Paragraph style={{
                                        fontSize: '17px',
                                        color: retroTheme.colors.textSecondary,
                                        margin: '0 auto',
                                        lineHeight: 1.6,
                                        maxWidth: '480px'
                                    }}>
                                        {state.coverText}
                                    </Typography.Paragraph>

                                    {/* Action Buttons */}
                                    <Space size={12} style={{marginTop: 8}}>
                                        {state.appleStoreLink && (
                                            <RetroButton 
                                                type="primary"
                                                onClick={() => window.open(state.appleStoreLink, '_blank')}
                                            >
                                                <Space>
                                                    <DownloadOutlined />
                                                    {promotionalText.button}
                                                </Space>
                                            </RetroButton>
                                        )}
                                        <RetroButton 
                                            type="secondary"
                                            onClick={() => window.location.href = '/tutorial'}
                                        >
                                            <Space>
                                                <StarOutlined />
                                                Learn More
                                            </Space>
                                        </RetroButton>
                                    </Space>

                                    {/* Rating & Stats */}
                                    <Row justify="center" style={{marginTop: 16}}>
                                        <Space split={<Divider type="vertical" style={{
                                            borderColor: 'rgba(255,215,0,0.1)',
                                            height: '40px'
                                        }}/>} size={48}>
                                            <Space direction="vertical" size={4} style={{textAlign: 'center'}}>
                                                <Rate disabled defaultValue={5} style={{
                                                    fontSize: 14,
                                                    color: retroTheme.colors.primary[500]
                                                }}/>
                                                <Typography.Text style={{
                                                    fontSize: '13px',
                                                    color: retroTheme.colors.textMuted
                                                }}>
                                                    4.9 Rating
                                                </Typography.Text>
                                            </Space>
                                            <Space direction="vertical" size={4} style={{textAlign: 'center'}}>
                                                <Typography.Text style={{
                                                    fontSize: '18px',
                                                    color: retroTheme.colors.text,
                                                    fontWeight: '600'
                                                }}>
                                                    100K+
                                                </Typography.Text>
                                                <Typography.Text style={{
                                                    fontSize: '13px',
                                                    color: retroTheme.colors.textMuted
                                                }}>
                                                    Downloads
                                                </Typography.Text>
                                            </Space>
                                        </Space>
                                    </Row>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* App Highlights */}
                <Row justify='center' align='top' style={{
                    padding: '64px 24px',
                    background: retroTheme.colors.surface
                }}>
                    <Col span={24} style={{maxWidth: '680px'}}>
                        <motion.div
                            initial={{y: 20, opacity: 0}}
                            whileInView={{y: 0, opacity: 1}}
                            transition={{duration: 0.6}}
                            viewport={{once: true}}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <div style={{textAlign: 'center', marginBottom: '32px'}}>
                                        <Tag color={retroTheme.colors.primary} style={{
                                            marginBottom: '16px',
                                            background: `${retroTheme.colors.primary}15`,
                                            borderColor: `${retroTheme.colors.primary}30`,
                                            color: retroTheme.colors.primaryLight
                                        }}>
                                            CORE FEATURES
                                        </Tag>
                                        <Typography.Title level={2} style={{
                                            marginBottom: '16px',
                                            color: retroTheme.colors.text
                                        }}>
                                            Powerful Emulator Features
                                        </Typography.Title>
                                        <Typography.Text style={{
                                            fontSize: '16px',
                                            color: retroTheme.colors.textSecondary
                                        }}>
                                            Experience smooth gameplay with rich features
                                        </Typography.Text>
                                    </div>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <FeatureCard 
                                        icon={<AppstoreOutlined style={{ color: '#D32F2F' }} />}
                                        title={<>High Performance <span style={{ fontSize: '16px' }}>🎁</span></>}
                                        description="Advanced emulation technology ensures smooth and stable gameplay"
                                    />
                                </Col>
                                <Col xs={24} sm={12}>
                                    <FeatureCard 
                                        icon={<CustomerServiceOutlined />}
                                        title="Save States"
                                        description="Multiple save slots for each game, save and load your progress anytime"
                                    />
                                </Col>
                                <Col xs={24} sm={12} style={{marginTop: '16px'}}>
                                    <FeatureCard 
                                        icon={<SafetyCertificateOutlined />}
                                        title="Cheat Support"
                                        description="Support for multiple cheat codes to enhance your gaming experience"
                                    />
                                </Col>
                                <Col xs={24} sm={12} style={{marginTop: '16px'}}>
                                    <FeatureCard 
                                        icon={<StarFilled />}
                                        title="Platform Support"
                                        description="Now supporting NES and SNES platforms for a wider range of games"
                                    />
                                </Col>
                            </Row>
                        </motion.div>
                    </Col>
                </Row>

                {/* User Reviews */}
                <Row justify='center' align='top' style={{
                    padding: '64px 24px',
                    background: retroTheme.colors.surface
                }}>
                    <Col span={24} style={{maxWidth: '680px'}}>
                        <motion.div
                            initial={{y: 20, opacity: 0}}
                            whileInView={{y: 0, opacity: 1}}
                            transition={{duration: 0.6}}
                            viewport={{once: true}}
                        >
                            <div style={{textAlign: 'center', marginBottom: '32px'}}>
                                <Tag color={retroTheme.colors.primary} style={{
                                    marginBottom: '16px',
                                    background: `${retroTheme.colors.primary}15`,
                                    borderColor: `${retroTheme.colors.primary}30`,
                                    color: retroTheme.colors.primaryLight
                                }}>
                                    TESTIMONIALS
                                </Tag>
                                <Typography.Title level={2} style={{
                                    marginBottom: '16px',
                                    color: retroTheme.colors.text,
                                    fontSize: '32px',
                                    fontWeight: '600',
                                    letterSpacing: '-0.02em'
                                }}>
                                    What Gamers Say
                                </Typography.Title>
                            </div>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Card bordered={false} style={{
                                        borderRadius: '0px',
                                        background: retroTheme.colors.surfaceSecondary,
                                        border: '2px solid #000',
                                        boxShadow: retroTheme.shadows.md,
                                        padding: '24px'
                                    }}>
                                        <Space direction="vertical" size={16}>
                                            <Rate disabled defaultValue={5} style={{
                                                color: retroTheme.colors.warning
                                            }} />
                                            <Typography.Paragraph italic style={{
                                                color: retroTheme.colors.textSecondary,
                                                fontSize: '16px',
                                                lineHeight: 1.6,
                                                margin: 0
                                            }}>
                                                "Excellent emulator with great performance..."
                                            </Typography.Paragraph>
                                            <Space>
                                                <Avatar
                                                    src="https://randomuser.me/api/portraits/men/32.jpg"
                                                    size={40}
                                                    style={{
                                                        border: '2px solid #000',
                                                        boxShadow: retroTheme.shadows.xs
                                                    }}
                                                />
                                                <div>
                                                    <Typography.Text strong style={{
                                                        color: retroTheme.colors.text,
                                                        display: 'block',
                                                        fontSize: '15px'
                                                    }}>
                                                        John Smith
                                                    </Typography.Text>
                                                    <Typography.Text style={{
                                                        color: retroTheme.colors.textSecondary,
                                                        fontSize: '14px'
                                                    }}>
                                                        Retro Gamer
                                                    </Typography.Text>
                                                </div>
                                            </Space>
                                        </Space>
                                    </Card>
                                </Col>
                            </Row>
                        </motion.div>
                    </Col>
                </Row>

                {/* Download CTA */}
                <Row justify='center' align='middle' style={{
                    padding: '64px 24px',
                    background: retroTheme.colors.surfaceSecondary,
                    border: '2px solid #000',
                    position: 'relative',
                    boxShadow: retroTheme.shadows.lg
                }}>
                    <Col span={24} style={{maxWidth: '680px'}}>
                        <Typography.Title level={2} style={{
                            color: retroTheme.colors.text,
                            fontSize: '36px',
                            fontFamily: 'Press Start 2P, cursive',
                            textAlign: 'center',
                            marginBottom: '32px'
                        }}>
                            {promotionalText.title}
                        </Typography.Title>
                        <div style={{textAlign: 'center'}}>
                            <RetroButton onClick={() => window.open(state.appleStoreLink, '_blank')}>
                                <Space>
                                    <DownloadOutlined />
                                    {promotionalText.button}
                                </Space>
                            </RetroButton>
                        </div>
                    </Col>
                </Row>

                {/* section list */}
                <SectionList sectionList={state.sectionList} />

                {/* policies */}
                <Row justify='center' align='top' style={{
                    backgroundColor: 'rgba(15, 15, 17, 0.95)',
                    padding: '56px 24px',
                    borderTop: '1px solid rgba(139, 92, 246, 0.15)'
                }}>
                    <Row justify='space-between' align='top' style={{
                        width: '100%',
                        maxWidth: '680px',
                        gap: '32px'
                    }}>
                        {/* Policy Column */}
                        <Col flex="1">
                            <Typography.Title level={4} style={{ 
                                margin: 0,
                                marginBottom: '24px',
                                fontSize: '18px',
                                fontWeight: '600',
                                color: '#FFFFFF',
                                letterSpacing: '-0.01em'
                            }}>
                                Policy
                            </Typography.Title>
                            
                            <Space direction="vertical" size={16} style={{ width: '100%' }}>
                                <Button type="text"
                                    href={state.appURL + '/policy/payment'}
                                    target='_blank'
                                    rel="noopener noreferrer"
                                    style={{
                                        background: 'rgba(139, 92, 246, 0.05)',
                                        height: 'auto',
                                        padding: '12px 16px',
                                        borderRadius: '12px',
                                        width: '100%',
                                        textAlign: 'left',
                                        border: '1px solid rgba(139, 92, 246, 0.15)',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            background: 'rgba(139, 92, 246, 0.1)',
                                        }
                                    }}
                                >
                                    <span style={{ 
                                        fontSize: '15px', 
                                        color: '#FFFFFF',
                                        fontWeight: '500'
                                    }}>
                                        Payment Policy
                                    </span>
                                </Button>

                                <Button type="text"
                                    href={state.appURL + '/policy/privacy'}
                                    target='_blank'
                                    rel="noopener noreferrer"
                                    style={{
                                        background: 'rgba(139, 92, 246, 0.05)',
                                        height: 'auto',
                                        padding: '12px 16px',
                                        borderRadius: '12px',
                                        width: '100%',
                                        textAlign: 'left',
                                        border: '1px solid rgba(139, 92, 246, 0.15)',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            background: 'rgba(139, 92, 246, 0.1)',
                                        }
                                    }}
                                >
                                    <span style={{ 
                                        fontSize: '15px', 
                                        color: '#FFFFFF',
                                        fontWeight: '500'
                                    }}>
                                        Privacy Policy
                                    </span>
                                </Button>

                                <Button type="text"
                                    href={state.appURL + '/policy/terms'}
                                    target='_blank'
                                    rel="noopener noreferrer"
                                    style={{
                                        background: 'rgba(139, 92, 246, 0.05)',
                                        height: 'auto',
                                        padding: '12px 16px',
                                        borderRadius: '12px',
                                        width: '100%',
                                        textAlign: 'left',
                                        border: '1px solid rgba(139, 92, 246, 0.15)',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            background: 'rgba(139, 92, 246, 0.1)',
                                        }
                                    }}
                                >
                                    <span style={{ 
                                        fontSize: '15px', 
                                        color: '#FFFFFF',
                                        fontWeight: '500'
                                    }}>
                                        Terms of Service
                                    </span>
                                </Button>
                            </Space>
                        </Col>

                        {/* Gaming Community Column */}
                        <Col flex="1">
                            <Typography.Title level={4} style={{ 
                                margin: 0,
                                marginBottom: '24px',
                                fontSize: '18px',
                                fontWeight: '600',
                                color: '#FFFFFF',
                                letterSpacing: '-0.01em'
                            }}>
                                {isChineseLang(getLanguage()) ? '玩家社区' : 'Gaming Community'}
                            </Typography.Title>
                            
                            <Space direction="vertical" size={16} style={{ width: '100%' }}>
                                <div>
                                    <Typography.Text style={{
                                        fontSize: '15px',
                                        color: 'rgba(255, 255, 255, 0.6)',
                                        marginBottom: '8px',
                                        display: 'block'
                                    }}>
                                        {isChineseLang(getLanguage()) ? 'QQ 玩家社区' : 'QQ Gaming Community'}
                                    </Typography.Text>
                                    <Button type="text"
                                        style={{
                                            background: 'rgba(139, 92, 246, 0.05)',
                                            height: 'auto',
                                            padding: '12px 16px',
                                            borderRadius: '12px',
                                            width: '100%',
                                            textAlign: 'left',
                                            border: '1px solid rgba(139, 92, 246, 0.15)',
                                            transition: 'all 0.3s ease',
                                            '&:hover': {
                                                background: 'rgba(139, 92, 246, 0.1)',
                                            }
                                        }}
                                    >
                                        <Space>
                                            <Avatar size={24} icon={<QqOutlined />} style={{ 
                                                background: 'linear-gradient(135deg, #00d2ff 0%, #3a7bd5 100%)',
                                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                            }} />
                                            <span style={{ color: '#FFFFFF' }}>98786882</span>
                                        </Space>
                                        <Typography.Text copyable={{ 
                                            text: '98786882',
                                            tooltips: [isChineseLang(getLanguage()) ? '复制' : 'Copy', 
                                                      isChineseLang(getLanguage()) ? '已复制!' : 'Copied!']
                                        }} style={{ color: '#60A5FA' }}/>
                                    </Button>
                                </div>

                                {/* 添加 Telegram 社区 */}
                                <div>
                                    <Typography.Text style={{
                                        fontSize: '15px',
                                        color: 'rgba(255, 255, 255, 0.6)',
                                        marginBottom: '8px',
                                        display: 'block'
                                    }}>
                                        {isChineseLang(getLanguage()) ? 'Telegram 频道' : 'Telegram Channel'}
                                    </Typography.Text>
                                    <Button type="text"
                                        style={{
                                            background: 'rgba(139, 92, 246, 0.05)',
                                            height: 'auto',
                                            padding: '12px 16px',
                                            borderRadius: '12px',
                                            width: '100%',
                                            textAlign: 'left',
                                            border: '1px solid rgba(139, 92, 246, 0.15)',
                                            transition: 'all 0.3s ease',
                                            '&:hover': {
                                                background: 'rgba(139, 92, 246, 0.1)',
                                            }
                                        }}
                                    >
                                        <Space>
                                            <Avatar size={24} icon={<SendOutlined />} style={{ 
                                                background: 'linear-gradient(135deg, #0088cc 0%, #0099ff 100%)',
                                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                            }} />
                                            <span style={{ color: '#FFFFFF' }}>UNDSClub</span>
                                        </Space>
                                        <Typography.Text copyable={{ 
                                            text: 'UNDSClub',
                                            tooltips: [isChineseLang(getLanguage()) ? '复制' : 'Copy', 
                                                      isChineseLang(getLanguage()) ? '已复制!' : 'Copied!']
                                        }} style={{ color: '#60A5FA' }}/>
                                    </Button>
                                </div>
                            </Space>
                        </Col>

                    </Row>
                </Row>
                

                <Row justify="center" align='middle' style={{
                    backgroundColor: retroTheme.colors.primary[50],
                    padding: '28px 24px'
                }}>
                    <Col>
                        <Typography.Text style={{
                            fontSize: 13,
                            color: retroTheme.colors.textMuted
                        }}>
                            Copyright © {new Date().getFullYear()} {state.appName}. All rights reserved.
                        </Typography.Text>
                    </Col>
                </Row>

            </Layout.Content>

            {/*
            <Layout.Footer>


            </Layout.Footer>
            */}

            </Layout>
    )
}

export default Mobile
