import React from 'react';
import { Layout, Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Layout style={{ minWidth: '100vw', minHeight: '100vh', padding: '40px 20px', textAlign: 'center' }}>
      <Title level={1}>404</Title>
      <Paragraph>页面未找到</Paragraph>
      <Button type="primary" onClick={() => navigate('/')}>
        返回首页
      </Button>
    </Layout>
  );
};

export default NotFound; 