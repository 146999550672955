import { Typography, Row, Col, Drawer, Button } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react'

function Privacy() {
  const [isMobile, setIsMobile] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)

  // 检测窗口大小变化
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  const textStyle = {
    color: 'white'
  };

  const tocStyle = {
    position: 'fixed',
    left: '20px',
    top: '100px',
    background: 'rgba(0, 0, 0, 0.6)',
    padding: '20px',
    borderRadius: '8px',
    width: '250px',
    zIndex: 1000,
    display: isMobile ? 'none' : 'block' // 在移动端隐藏固定目录
  };

  const tocItemStyle = {
    ...textStyle,
    cursor: 'pointer',
    display: 'block',
    marginBottom: '10px',
    fontSize: '14px',
  };

  const mainContentStyle = {
    marginLeft: isMobile ? '0' : '280px',
    width: isMobile ? '100%' : 'calc(100% - 280px)',
    transition: 'all 0.3s'
  };

  const mobileMenuButtonStyle = {
    position: 'fixed',
    top: '20px',
    left: '20px',
    zIndex: 1000,
    display: isMobile ? 'block' : 'none'
  };

  const TableOfContents = () => (
    <>
      <Typography.Title level={4} style={textStyle}>Contents</Typography.Title>
      <Typography style={tocItemStyle} onClick={() => {
        document.location = '#1';
        isMobile && setDrawerVisible(false);
      }}>
        1. What Information Do We Collect?
      </Typography>
      <Typography style={tocItemStyle} onClick={() => {
        document.location = '#2';
        isMobile && setDrawerVisible(false);
      }}>
        2. How Do We Use Your Information?
      </Typography>
      <Typography style={tocItemStyle} onClick={() => {
        document.location = '#3';
        isMobile && setDrawerVisible(false);
      }}>
        3. Will Your Information Be Shared?
      </Typography>
      <Typography style={tocItemStyle} onClick={() => {
        document.location = '#4';
        isMobile && setDrawerVisible(false);
      }}>
        4. Third-Party Services and SDKs
      </Typography>
      <Typography style={tocItemStyle} onClick={() => {
        document.location = '#5';
        isMobile && setDrawerVisible(false);
      }}>
        5. How Long Do We Keep Your Information?
      </Typography>
      <Typography style={tocItemStyle} onClick={() => {
        document.location = '#6';
        isMobile && setDrawerVisible(false);
      }}>
        6. How Do We Keep Your Information Safe?
      </Typography>
      <Typography style={tocItemStyle} onClick={() => {
        document.location = '#7';
        isMobile && setDrawerVisible(false);
      }}>
        7. Do We Collect From Minors?
      </Typography>
      <Typography style={tocItemStyle} onClick={() => {
        document.location = '#8';
        isMobile && setDrawerVisible(false);
      }}>
        8. What Are Your Privacy Rights?
      </Typography>
      <Typography style={tocItemStyle} onClick={() => {
        document.location = '#9';
        isMobile && setDrawerVisible(false);
      }}>
        9. Do-Not-Track Features
      </Typography>
      <Typography style={tocItemStyle} onClick={() => {
        document.location = '#10';
        isMobile && setDrawerVisible(false);
      }}>
        10. California Residents Rights
      </Typography>
      <Typography style={tocItemStyle} onClick={() => {
        document.location = '#11';
        isMobile && setDrawerVisible(false);
      }}>
        11. Policy Updates
      </Typography>
    </>
  );

  return (
    <Typography component='div' style={{
      'height': '100vh', 
      'overflow': 'auto',
      'color': 'white'
    }}>
        <Button 
          icon={<MenuOutlined />}
          style={mobileMenuButtonStyle}
          onClick={() => setDrawerVisible(true)}
        />

        <Drawer
          title="Contents"
          placement="left"
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          bodyStyle={{ background: '#1f1f1f', color: 'white' }}
          headerStyle={{ background: '#1f1f1f', color: 'white' }}
        >
          <TableOfContents />
        </Drawer>

        <div style={tocStyle}>
          <TableOfContents />
        </div>

        <div style={mainContentStyle}>
          <Row type='flex' justify='center' style={{'margin': '20px'}}>
            <Col span={isMobile ? 24 : 20}>
              <Typography.Title level={2} style={textStyle}>
                  PRIVACY POLICY
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
              Last updated: January 1, 2025
              </Typography.Paragraph>

              <Typography.Title level={3} style={textStyle}>
                  AGREEMENT TO PRIVACY POLICY
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                  Thank you for choosing to be part of our community at UNDS  (“we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our notice, or our practices with regards to your personal information, please contact us at unds@hiappstore.com.
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
              When you visit our website https://unds.hiappstore.com, use our mobile application, and utilize our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Sites or Apps and our services.
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
              This privacy notice applies to all information collected through our website (such as https://unds.hiappstore.com) and mobile application ("Apps"), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy notice as the "Services").
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
                  Please read this privacy notice carefully as it will help you make informed decisions about sharing your personal information with us.
              </Typography.Paragraph>


              <Typography.Title level={3} style={textStyle}>
                  TABLE OF CONTENTS
              </Typography.Title>

              <Typography style={textStyle} onClick={() => {
                  document.location = '#1';
                  return false;
                  }}>
                  1. WHAT INFORMATION DO WE COLLECT?
              </Typography>

              <Typography style={textStyle} onClick={() => {
                  document.location = '#2';
                  return false;
                  }}>
                  2. HOW DO WE USE YOUR INFORMATION?
              </Typography>

              <Typography style={textStyle} onClick={() => {
                  document.location = '#3';
                  return false;
                  }}>
                  3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </Typography>

              <Typography style={textStyle} onClick={() => {
                  document.location = '#4';
                  return false;
                  }}>
              4. THIRD-PARTY SERVICES AND SDKs
              </Typography>
              
              
              <Typography style={textStyle} onClick={() => {
                  document.location = '#5';
                  return false;
                  }}>
                  5. HOW LONG DO WE KEEP YOUR INFORMATION?
              </Typography>

              <Typography style={textStyle} onClick={() => {
                  document.location = '#6';
                  return false;
                  }}>
                  6. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </Typography>

              <Typography style={textStyle} onClick={() => {
                  document.location = '#7';
                  return false;
                  }}>
                  7. DO WE COLLECT INFORMATION FROM MINORS?
              </Typography>

              <Typography style={textStyle} onClick={() => {
                  document.location = '#8';
                  return false;
                  }}>
                  8. WHAT ARE YOUR PRIVACY RIGHTS?
              </Typography>

              <Typography style={textStyle} onClick={() => {
                  document.location = '#9';
                  return false;
                  }}>
                  9. CONTROLS FOR DO-NOT-TRACK FEATURES
              </Typography>

              <Typography style={textStyle} onClick={() => {
                  document.location = '#10';
                  return false;
                  }}>
                  10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </Typography>

              <Typography style={textStyle} onClick={() => {
                  document.location = '#11';
                  return false;
                  }}>
                  11. DO WE MAKE UPDATES TO THIS POLICY?
              </Typography>
              {/*
              <Typography style={{'color': 'blue'}} onClick={() => {
                  document.location = '#15';
                  return false;
                  }}>
                  15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
              </Typography>
              */}

              <Typography.Title level={3} id='1' style={textStyle}>
                  1. WHAT INFORMATION DO WE COLLECT?
              </Typography.Title>

              <Typography.Title level={4} style={textStyle}>
                  Information automatically collected
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                  In Short: Some information — such as IP address and/or browser and device characteristics — is collected automatically when you visit our Services or Apps.
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
                  We automatically collect certain information when you visit, use or navigate the Services or Apps. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services or Apps and other technical information. This information is primarily needed to maintain the security and operation of our Services or Apps, and for our internal analytics and reporting purposes.
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
                  Like many businesses, we also collect information through cookies and similar technologies.
              </Typography.Paragraph>
              <Typography.Title level={4} style={textStyle}>
  Information collected through our Apps
</Typography.Title>

<Typography.Paragraph style={textStyle}>
    In Short: We only collect information regarding your purchase status when you make in-app purchases on our mobile application.
</Typography.Paragraph>

<Typography.Paragraph style={textStyle}>
    If you use our Apps, we may collect the following information:
</Typography.Paragraph>

<Typography.Paragraph style={textStyle}>
    Purchase Information. We collect information about the success or failure of the transactions you undertake within our app. This information is used solely to enable and improve the transaction process.
</Typography.Paragraph>

              <Typography.Title level={4} id='2' style={textStyle}>
  2. HOW DO WE USE YOUR INFORMATION?
</Typography.Title>

<Typography.Paragraph style={textStyle}>
    In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
</Typography.Paragraph>

<Typography.Paragraph style={textStyle}>
    We use personal information collected via our Services or Apps for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests ("Business Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the specific processing grounds we rely on next to each purpose listed below.
</Typography.Paragraph>

<Typography.Paragraph style={textStyle}>
    Here are the ways in which we use your information:
</Typography.Paragraph>

    <Typography.Paragraph style={textStyle}>
        <strong>To send administrative information to you.</strong> We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies. [Legal Reasons]
    </Typography.Paragraph>

    <Typography.Paragraph style={textStyle}>
        <strong>To protect our Services.</strong> We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention). [Business Purposes, Legal Reasons]
    </Typography.Paragraph>

    <Typography.Paragraph style={textStyle}>
        <strong>To enforce our terms, conditions and policies</strong> for Business Purposes, Legal Reasons and/or possibly Contractual.
    </Typography.Paragraph>

    <Typography.Paragraph style={textStyle}>
        <strong>To respond to legal requests and prevent harm.</strong> If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond. [Legal Reasons]
    </Typography.Paragraph>

    <Typography.Paragraph style={textStyle}>
        <strong>To manage user accounts.</strong> We may use your information for the purposes of managing our account and keeping it in working order. [Business Purposes]
    </Typography.Paragraph>

    <Typography.Paragraph style={textStyle}>
        <strong>To deliver services to the user.</strong> We may use your information to provide you with the requested service. [Contractual]
    </Typography.Paragraph>

    <Typography.Paragraph style={textStyle}>
        <strong>To respond to user inquiries/offer support to users.</strong> We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services. [Business Purposes]
    </Typography.Paragraph>

    <Typography.Paragraph style={textStyle}>
        <strong>For other Business Purposes.</strong> We may use your information for data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Services, products, marketing, and your experience. [Business Purposes]
    </Typography.Paragraph>

              <Typography.Title level={4} id='3' style={textStyle}>
                  3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
              We do not share your personal information with third parties except as required by law. We value your privacy and understand the importance of your personal information. We will only disclose your information where required by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.
              </Typography.Paragraph>


              <Typography.Title level={3} id='4' style={textStyle}>
                  4. THIRD-PARTY SERVICES AND SDKs
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                  We utilize various third-party services and SDKs to enhance the functionality of our website and improve user experience. These services include Firebase and RevenueCat, which help us manage app functionalities and in-app purchases.
              </Typography.Paragraph>

              <Typography.Title level={4} style={textStyle}>
                  Firebase
              </Typography.Title>
              <Typography.Paragraph style={textStyle}>
                  Purpose: To enhance app functionality and improve user experience through features like user authentication, database management, and crash analytics.
              </Typography.Paragraph>
              <Typography.Paragraph style={textStyle}>
                  Data Collected: Includes, but is not limited to, user interactions with the app, performance metrics, and crash data to ensure smooth operation and identify issues.
              </Typography.Paragraph>
              <Typography.Paragraph style={textStyle}>
                  Privacy Policy: <a href='https://firebase.google.com/support/privacy' rel="noopener noreferrer" target='_blank' style={textStyle}>https://firebase.google.com/support/privacy</a>
              </Typography.Paragraph>

              <Typography.Title level={4} style={textStyle}>
                  RevenueCat
              </Typography.Title>
              <Typography.Paragraph style={textStyle}>
                  Purpose: To manage in-app purchases and subscriptions efficiently.
              </Typography.Paragraph>
              <Typography.Paragraph style={textStyle}>
                  Data Collected: Includes purchase history, subscription details, and payment tokens.
              </Typography.Paragraph>
              <Typography.Paragraph style={textStyle}>
                  Privacy Policy: <a href='https://app.revenuecat.com/overview' rel="noopener noreferrer" target='_blank' style={textStyle}>https://app.revenuecat.com/overview</a>
              </Typography.Paragraph>

              <Typography.Title level={4} style={textStyle}>
                  Microsoft
              </Typography.Title>
              <Typography.Paragraph style={textStyle}>
                  Purpose: To provide analytics and insights into user interactions and improve service performance.
              </Typography.Paragraph>
              <Typography.Paragraph style={textStyle}>
                  Data Collected: Includes user interaction data, performance metrics, and other relevant analytics data.
              </Typography.Paragraph>
              <Typography.Paragraph style={textStyle}>
                  Privacy Policy: <a href='https://privacy.microsoft.com/en-us/privacystatement' rel="noopener noreferrer" target='_blank' style={textStyle}>https://privacy.microsoft.com/en-us/privacystatement</a>
              </Typography.Paragraph>

              <Typography.Title level={4} style={textStyle}>
                  Google Analytics
              </Typography.Title>
              <Typography.Paragraph style={textStyle}>
                  Purpose: To track and report website traffic and user behavior to improve our services.
              </Typography.Paragraph>
              <Typography.Paragraph style={textStyle}>
                  Data Collected: Includes user interactions, session durations, and other web analytics data.
              </Typography.Paragraph>
              <Typography.Paragraph style={textStyle}>
                  Privacy Policy: <a href='https://policies.google.com/privacy' rel="noopener noreferrer" target='_blank' style={textStyle}>https://policies.google.com/privacy</a>
              </Typography.Paragraph>

              <Typography.Title level={4} id='5' style={textStyle}>
                  5. HOW LONG DO WE KEEP YOUR INFORMATION?
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                  We do not collect any personal information, therefore there is no need to retain or process such information.
              </Typography.Paragraph>

              <Typography.Title level={3} id='6' style={textStyle}>
                  6. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                  Since we do not collect any personal information, there are no data security risks associated with personal data. We are committed to maintaining the overall security of our website to ensure there are no vulnerabilities.
              </Typography.Paragraph>

              <Typography.Title level={4} id='7' style={textStyle}>
                  7. DO WE COLLECT INFORMATION FROM MINORS?
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                  In Short:  We do not knowingly collect data from or market to children under 18 years of age.
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
                  We do not knowingly solicit data from or market to children under 18 years of age. By using the Services or Apps, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services or Apps. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at unds@hiappstore.com.
              </Typography.Paragraph>


              <Typography.Title level={4} id='8' style={textStyle}>
                  8. WHAT ARE YOUR PRIVACY RIGHTS?
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                  In Short:  In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
                  In some regions (like the European Economic Area), you have certain rights under applicable data protection laws.
                  These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the <Typography.Text style={textStyle} onClick={() => {
                  document.location = '#15';
                  return false;
                  }}>contact details</Typography.Text> provided below. We will consider and act upon any request in accordance with applicable data protection laws.
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
                  If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
                  If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details <a href='https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080' rel="noopener noreferrer" target='_blank' style={textStyle}>here</a> If you have questions or comments about your privacy rights, you may email us at unds@hiappstore.com.
              </Typography.Paragraph>

              <Typography.Title level={4} id='9' style={textStyle}>
                  9. CONTROLS FOR DO-NOT-TRACK FEATURES
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                  Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
              </Typography.Paragraph>


              <Typography.Title level={4} id='10' style={textStyle}>
                  10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                  In Short:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
                  California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
                  If you are under 18 years of age, reside in California, and have a registered account with the Services or Apps, you have the right to request removal of unwanted data that you publicly post on the Services or Apps. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services or Apps, but please be aware that the data may not be completely or comprehensively removed from our systems.
              </Typography.Paragraph>


              <Typography.Title level={4} id='11' style={textStyle}>
                  11. DO WE MAKE UPDATES TO THIS POLICY?
              </Typography.Title>

              <Typography.Paragraph style={textStyle}>
                  In Short:  Yes, we will update this policy as necessary to stay compliant with relevant laws.
              </Typography.Paragraph>

              <Typography.Paragraph style={textStyle}>
                  We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
              </Typography.Paragraph>

          </Col>
      </Row>
      </div>
  </Typography>
)
}

export default Privacy
