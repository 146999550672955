import {
    Typography,
    Layout,
    Row,
    Col,
    Affix,
    Image,
    Button,
    Tooltip,
    Space,
    Card,
} from 'antd'

import {
    DownloadOutlined,
} from '@ant-design/icons'

import {
    motion,
} from 'framer-motion'

import {
    useContext,
} from 'react'

import { Context } from './store/Context'

// props: image
function FloatImageCol(props) {
    return (
        <Col span={12}>
            <Row justify='center'>
            <motion.div 
                    initial={{y: 300, opacity: 0}} 
                    whileInView={{y: 0, opacity: 1, transition: {type: 'spring', bounce: 0, duration: 1}}} 
                    viewport={{once: true}}>
                <Image height={400} preview={false} src={props.image} style={{'boxShadow': '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}></Image>
            </motion.div>
            </Row>
        </Col>
    )
}

// props title, text
function FloatTextCol(props) {
    return (
        <Col span={12}>
            <motion.div 
                    initial={{y: 300, opacity: 0}} 
                    whileInView={{y: 0, opacity: 1, transition: {type: 'spring', bounce: 0, duration: 1}}} 
                    viewport={{once: true}}>
                <Row justify='center' style={{'padding': '0px 50px'}}>
                    <Typography.Title>
                        {props.title}
                    </Typography.Title>
                </Row>
                <Row justify='center' style={{'padding': '0px 50px'}}>
                    <Typography style={{'fontSize': '16px'}}>
                        {props.text}
                    </Typography>
                </Row>
            </motion.div>
        </Col>
    )
}

// props: sectionItem, backgroundColor
function SectionItemImageOnTheLeft(props) {
    return (
        <Row justify='center' align='middle' style={{
            'backgroundColor': props.backgroundColor, 
            'height': '500px', 
            'padding': '40px'
        }}>
            <Row justify='center' align='middle' style={{'maxWidth': '2000px'}}>
                <FloatImageCol image={props.sectionItem.image} />
                <FloatTextCol title={props.sectionItem.title} text={props.sectionItem.text} />
            </Row>
        </Row>
    )
}

// props: sectionItem, backgroundColor
function SectionItemImageOnTheRight(props) {
    return (
        <Row justify='center' align='middle' style={{
            'backgroundColor': props.backgroundColor, 
            'height': '500px', 
            'padding': '40px'
        }}>
            <Row justify='center' align='middle' style={{'maxWidth': '2000px'}}>
                <FloatTextCol title={props.sectionItem.title} text={props.sectionItem.text} />
                <FloatImageCol image={props.sectionItem.image} />
            </Row>
        </Row>
    )
}

// props: sectionList
function SectionList(props) {
    return (
        <>
        {
            props.sectionList.map((sectionItem, index) => {
                return (
                    index % 2 === 0?
                        <SectionItemImageOnTheLeft sectionItem={sectionItem} backgroundColor={'white'} />
                        :
                        <SectionItemImageOnTheRight sectionItem={sectionItem} backgroundColor={null} />
                )

            })
        }
        </>
    )
}

function Desktop() {
    const {state, dispatch} = useContext(Context)
    
    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Layout style={{'minWidth': '1000px'}}>
            <Affix offsetTop={0}>
                <Layout.Header style={{
                    'background': 'rgba(255, 255, 255, 0.98)',
                    'height': '88px',
                    'boxShadow': '0 1px 3px rgba(0,0,0,0.05)',
                    'backdropFilter': 'blur(10px)',
                    'WebkitBackdropFilter': 'blur(10px)'
                }}>
                    <Row justify='center' align='middle' style={{'height': '100%'}}>
                        <Row justify='space-between' align='middle' style={{
                            'maxWidth': '1200px',
                            'width': '100%',
                            'padding': '0 24px'
                        }}>
                            <Col style={{'cursor': 'pointer'}} onClick={() => { window.scrollTo(0, 0)}}>
                                <Row align='middle'>
                                    <Image 
                                        height={44} 
                                        preview={false} 
                                        src={state.appLogo}
                                        style={{
                                            'objectFit': 'contain'
                                        }}
                                    />
                                    <Typography.Title level={4} style={{
                                        'margin': '0 0 0 12px',
                                        'fontSize': '24px',
                                        'fontWeight': '500'
                                    }}>
                                        {state.appName}
                                    </Typography.Title>
                                </Row>
                            </Col>
                            
                            <Col>
                                <Space size={24}>
                                 

                                    <motion.div
                                        whileHover={{ scale: 1.03 }}
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        <Button 
                                            size="large"
                                            onClick={() => window.open('/support', '_blank')}
                                            style={{
                                                'borderRadius': '20px',
                                                'padding': '4px 24px',
                                                'height': 'auto',
                                                'fontWeight': '500',
                                                'display': 'flex',
                                                'alignItems': 'center',
                                                'gap': '8px',
                                                'border': '1px solid #d9d9d9',
                                                'background': 'transparent'
                                            }}
                                        >
                                            Support
                                        </Button>
                                    </motion.div>

                                    <motion.div
                                        whileHover={{ scale: 1.03 }}
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        <Button 
                                            type="primary" 
                                            size="large"
                                            onClick={() => window.open(state.appleStoreLink, '_blank')}
                                            icon={<DownloadOutlined />}
                                            style={{
                                                'borderRadius': '20px',
                                                'padding': '4px 24px',
                                                'height': 'auto',
                                                'fontWeight': '500',
                                                'background': 'linear-gradient(90deg, #1677ff 0%, #4096ff 100%)',
                                                'border': 'none',
                                                'boxShadow': '0 4px 15px rgba(24, 144, 255, 0.15)',
                                                'display': 'flex',
                                                'alignItems': 'center',
                                                'gap': '8px'
                                            }}
                                            className="hover:shadow-lg transition-shadow duration-300"
                                        >
                                            Download
                                        </Button>
                                    </motion.div>
                                </Space>
                            </Col>
                        </Row>
                    </Row>
                </Layout.Header>
            </Affix>

            <Layout.Content>
  {/* cover headline */}
  <Row justify='center' align='middle' style={{
    'background': 'linear-gradient(180deg, #fbfbfd 0%, #fff 100%)',
    'minHeight': '100vh',
    'padding': '120px 24px 80px',
    'position': 'relative',
    'overflow': 'hidden'
  }}>
        <Row justify='center' align='middle' style={{
            'maxWidth': '1200px',
            'width': '100%',
            'position': 'relative',
            'zIndex': 1
        }}>
            <Col span={24}>
                <motion.div
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <Row justify='center' style={{'marginBottom': '48px'}}>
                        <Col style={{'textAlign': 'center'}}>
                            <Typography.Title style={{
                                'fontSize': '56px',
                                'lineHeight': '1.1',
                                'fontWeight': '600',
                                'marginBottom': '24px',
                                'background': 'linear-gradient(90deg, #1d1d1f 0%, #434343 100%)',
                                'WebkitBackgroundClip': 'text',
                                'WebkitTextFillColor': 'transparent',
                            }}>
                                {state.appName}
                            </Typography.Title>
                            <Typography.Paragraph style={{
                                'fontSize': '27px',
                                'lineHeight': '1.4',
                                'color': '#1d1d1f',
                                'margin': '0 auto',
                                'maxWidth': '600px',
                                'fontWeight': '400'
                            }}>
                                {state.coverText}
                            </Typography.Paragraph>
                        </Col>
                    </Row>

                    <Row id="download" justify='center' style={{'marginBottom': '64px'}}>
                        <Space size={24}>
                            {state.appleStoreLink &&
                                <motion.div
                                    whileHover={{ scale: 1.03 }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <a href={state.appleStoreLink} target='_blank' rel="noopener noreferrer">
                                        <Image 
                                            height={48} 
                                            preview={false} 
                                            src={state.appleStoreBadge}
                                            style={{ filter: 'drop-shadow(0 4px 12px rgba(0,0,0,0.1))' }}
                                        />
                                    </a>
                                </motion.div>
                            }
                            {state.googlePlayLink &&
                                <motion.div
                                    whileHover={{ scale: 1.03 }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <a href={state.googlePlayLink} target='_blank' rel="noopener noreferrer">
                                        <Image 
                                            height={48} 
                                            preview={false} 
                                            src={state.googlePlayBadge}
                                            style={{ filter: 'drop-shadow(0 4px 12px rgba(0,0,0,0.1))' }}
                                        />
                                    </a>
                                </motion.div>
                            }
                        </Space>
                    </Row>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 60 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.3 }}
                >
                    <Row justify='center' align='middle' style={{ marginTop: '40px' }}>
                        <Col span={24} style={{ position: 'relative' }}>
                            {/* 主要产品展示图 */}
                            {/* <Row justify='center'>
                                <Image 
                                    width="100%"
                                    preview={false} 
                                    src={state.coverImage}
                                    style={{
                                        'maxWidth': '1000px',
                                        'objectFit': 'contain',
                                        'position': 'relative',
                                        'zIndex': 2
                                    }}
                                />
                            </Row> */}

                            {/* 装饰性背景元素 */}
                            <div style={{
                                'position': 'absolute',
                                'width': '140%',
                                'height': '140%',
                                'left': '-20%',
                                'top': '-20%',
                                'background': 'radial-gradient(circle at center, rgba(0,0,0,0.03) 0%, transparent 70%)',
                                'zIndex': 1
                            }} />

                            {/* 产品亮点展示 */}
                            <Row id="features" justify='space-around' 
                                style={{
                                    'maxWidth': '1200px',
                                    'margin': '48px auto 0',
                                    'padding': '0 24px',
                                    'background': 'white'
                                }}
                            >
                                <Col span={8} style={{ textAlign: 'center' }}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.6, delay: 0.6 }}
                                    >
                                        <Typography.Title level={3} style={{
                                            'fontSize': '24px',
                                            'marginBottom': '12px'
                                        }}>
                                            High Performance
                                        </Typography.Title>
                                        <Typography.Text style={{
                                            'fontSize': '16px',
                                            'color': '#666'
                                        }}>
                                            Fast and smooth emulation experience
                                        </Typography.Text>
                                    </motion.div>
                                </Col>
                                <Col span={8} style={{ textAlign: 'center' }}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.6, delay: 0.8 }}
                                    >
                                        <Typography.Title level={3} style={{
                                            'fontSize': '24px',
                                            'marginBottom': '12px'
                                        }}>
                                            Wide Compatibility
                                        </Typography.Title>
                                        <Typography.Text style={{
                                            'fontSize': '16px',
                                            'color': '#666'
                                        }}>
                                            Support various ROMs and games
                                        </Typography.Text>
                                    </motion.div>
                                </Col>
                                <Col span={8} style={{ textAlign: 'center' }}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.6, delay: 1 }}
                                    >
                                        <Typography.Title level={3} style={{
                                            'fontSize': '24px',
                                            'marginBottom': '12px'
                                        }}>
                                            Save States
                                        </Typography.Title>
                                        <Typography.Text style={{
                                            'fontSize': '16px',
                                            'color': '#666'
                                        }}>
                                            Save and load your game progress anytime
                                        </Typography.Text>
                                    </motion.div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </motion.div>
            </Col>
        </Row>

        {/* 背景装饰 */}
        <div style={{
            'position': 'absolute',
            'top': '0',
            'left': '0',
            'right': '0',
            'bottom': '0',
            'background': 'radial-gradient(circle at 30% 20%, rgba(0,0,0,0.02) 0%, transparent 20%)',
            'zIndex': 0
        }} />
    </Row>


                {/* endorsement list */}
                <Row justify='center' align='middle' style={{
                    'backgroundColor': 'white',
                    'height': '500px', 
                    'padding': '40px'
                }}>
                <Row justify='center' align='middle' style={{'maxWidth': '2000px'}}>
                
                    <motion.div 
                            initial={{y: 300, opacity: 0}} 
                            whileInView={{y: 0, opacity: 1, transition: {type: 'spring', bounce: 0, duration: 1}}} 
                            viewport={{once: true}}>
                        <Row justify='center'>
                            <Typography.Title>
                                {state.endorsementTitle}
                            </Typography.Title>
                        </Row>
                        <Row justify='center'>
                            <Typography style={{'fontSize': '16px'}}>
                                {state.endorsementText}
                            </Typography>
                        </Row>
                        <Row justify='center'>
                        <Typography style={{'fontSize': '14px', 'color': 'gray'}}>
                            {state.endorsementDesc}
                        </Typography>
                    </Row>

                        <Row justify='center' style={{'marginTop': '50px'}}>
                            {
                                state.endorsementList.map((endorsementItem, index) => {
                                return (
                                    <Col span={6} style={{'padding': '0 20px'}}>
                                    <Tooltip placement='top' title={endorsementItem.title} color={endorsementItem.titleColor}>
                                        <a href={endorsementItem.URL} target='_blank' rel="noopener noreferrer">
                                        <div style={{'display': 'flex', 'justifyContent': 'center'}}>
                                            <Image 
                                            width={200} 
                                            height={100} 
                                            preview={false} 
                                            src={endorsementItem.image} 
                                            style={{
                                                'objectFit': 'contain',
                                                'boxShadow': '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                                            }}
                                            />
                                        </div>
                                        </a>
                                    </Tooltip>
                                    </Col>
                                )
                                })
                            }
                            </Row>
                    </motion.div>
                    
                </Row>
                </Row>

                {/* section list */}
                <SectionList sectionList={state.sectionList} />


                {/* policies */}
                <Row justify='center' align='middle' style={{
                    'backgroundColor': 'white', 
                    'borderTop': '1px solid #f0f0f0',
                    'padding': '60px 24px'
                }}>
                    <Row justify='space-between' align='top' style={{
                        'maxWidth': '1200px', 
                        'width': '100%'
                    }}>
                        {/* Logo Section */}
                        <Col style={{'marginBottom': '24px'}}>
                            <Row justify='start' align='middle' 
                                style={{
                                    'cursor': 'pointer', 
                                    'marginBottom': '24px'
                                }} 
                                onClick={() => { window.scrollTo(0, 0)}}>
                                <Image 
                                    height={32} 
                                    preview={false} 
                                    src={state.appLogo} 
                                    style={{'opacity': '0.85'}}
                                />
                                <Typography.Title 
                                    level={4} 
                                    style={{
                                        'margin': '0 0 0 12px',
                                        'color': '#1f1f1f'
                                    }}>
                                    {state.appName}
                                </Typography.Title>
                            </Row>
                            <motion.div
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                            >
                                <Button 
                                    style={{
                                        'borderRadius': '20px',
                                        'padding': '4px 24px',
                                        'height': 'auto',
                                        'background': '#1f1f1f',
                                        'border': 'none',
                                        'color': 'white'
                                    }}
                                    icon={<DownloadOutlined />}
                                    size='large'
                                    onClick={() => window.open(state.appleStoreLink, '_blank')}
                                >
                                    Download
                                </Button>
                            </motion.div>
                        </Col>

                        {/* Links Sections */}
                        <Col>
                            <Typography.Title level={5} style={{
                                'marginBottom': '16px',
                                'color': '#1f1f1f'
                            }}>
                                Policy
                            </Typography.Title>
                            <Space direction="vertical" size={12}>
                                <Typography.Link 
                                    href="/policy/privacy" 
                                    target="_blank"
                                    style={{
                                        'color': '#666',
                                        'transition': 'color 0.3s'
                                    }}
                                    className="footer-link"
                                >
                                    Privacy Policy
                                </Typography.Link>
                                <Typography.Link 
                                    href="/policy/terms" 
                                    target="_blank"
                                    style={{
                                        'color': '#666',
                                        'transition': 'color 0.3s'
                                    }}
                                    className="footer-link"
                                >
                                    Terms of Service
                                </Typography.Link>
                                <Typography.Link 
                                    href="/policy/payment" 
                                    target="_blank"
                                    style={{
                                        'color': '#666',
                                        'transition': 'color 0.3s'
                                    }}
                                    className="footer-link"
                                >
                                    Payment Policy
                                </Typography.Link>
                            </Space>
                        </Col>

                        <Col>
                            <Typography.Title level={5} style={{
                                'marginBottom': '16px',
                                'color': '#1f1f1f'
                            }}>
                                Support
                            </Typography.Title>
                            <Space direction="vertical" size={12}>
                                <Typography.Link 
                                    href="/tutorial" 
                                    target="_blank"
                                    style={{
                                        'color': '#666',
                                        'transition': 'color 0.3s'
                                    }}
                                    className="footer-link"
                                >
                                    Tutorial
                                </Typography.Link>
                                <Typography.Link 
                                    href="/faq" 
                                    target="_blank"
                                    style={{
                                        'color': '#666',
                                        'transition': 'color 0.3s'
                                    }}
                                    className="footer-link"
                                >
                                    FAQ
                                </Typography.Link>
                                <Typography.Link 
                                    href="/contact" 
                                    target="_blank"
                                    style={{
                                        'color': '#666',
                                        'transition': 'color 0.3s'
                                    }}
                                    className="footer-link"
                                >
                                    Contact Us
                                </Typography.Link>
                            </Space>
                        </Col>
                    </Row>                
                </Row>

                {/* Copyright */}
                <Row 
                    justify="center" 
                    align='middle' 
                    style={{
                        'backgroundColor': 'white',
                        'borderTop': '1px solid #f0f0f0',
                        'padding': '24px'
                    }}
                >
                    <Typography.Text style={{
                        'fontSize': 14,
                        'color': '#666'
                    }}>
                        © {new Date().getFullYear()} {state.appName}. All rights reserved.
                    </Typography.Text>
                </Row>

            </Layout.Content>

        </Layout>
    )
}

export default Desktop
