// @Tutorial.js
// Unds: Tutorial Component
import React, { useState } from 'react';
import { Layout, Button, Typography, Row, Col, Space, Tabs, Divider, Alert } from 'antd';
import { Context } from './store/Context';
import { useContext } from 'react';
import { ExclamationCircleOutlined, GlobalOutlined } from '@ant-design/icons';
import ReactDOM from 'react-dom';

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const getDefaultLanguage = () => {
  const language = navigator.language || navigator.userLanguage;
  return language.toLowerCase().startsWith('zh-cn');
};

const Tutorial = () => {
  const { state } = useContext(Context);
  const [isChinese, setIsChinese] = useState(getDefaultLanguage());
  const [videoError, setVideoError] = useState(false);

  const toggleLanguage = () => {
    setIsChinese(!isChinese);
    setVideoError(false);
  };

  const handleVideoError = (e) => {
    console.log('Video loading error:', e);
    setVideoError(true);
  };

  const renderVideoContent = () => {
    if (videoError) {
      return (
        <Alert
          message={isChinese ? "视频加载失败" : "Video Loading Failed"}
          description={isChinese ? "抱歉，视频暂时无法播放。请稍后再试。" : "Sorry, the video cannot be played right now. Please try again later."}
          type="error"
          showIcon
          style={{ 
            maxWidth: '800px', 
            margin: '20px auto',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
          }}
        />
      );
    }

    return (
      <Row justify="center">
        <Col xs={24} md={20} lg={16}>
          <div style={{ 
            maxWidth: '800px',
            margin: '20px auto',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            overflow: 'hidden'
          }}>
            <video
              style={{
                width: '100%',
                display: 'block',
                borderRadius: '8px'
              }}
              controls
              onError={handleVideoError}
              playsInline
              key={isChinese ? 'zh' : 'en'}
            >
              <source 
                src={isChinese ? "/videos/unds_tutorial_cn.mp4" : "/videos/unds_tutorial_cn.mp4"} 
                type="video/mp4" 
              />
            </video>
          </div>

          <div style={{
            maxWidth: '800px',
            margin: '20px auto',
            padding: '20px',
            background: '#fff',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
          }}>
            <Typography.Title level={4} style={{ marginBottom: '16px' }}>
              {isChinese ? "操作步骤" : "Instructions"}
            </Typography.Title>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Typography.Paragraph>
                <strong>1. {isChinese ? "打开文件管理" : "Open File Manager"}</strong>
                <br />
                {isChinese 
                  ? "在应用底部导航栏中点击「文件」选项。" 
                  : "Click the 'Files' option in the bottom navigation bar."}
              </Typography.Paragraph>
              
              <Typography.Paragraph>
                <strong>2. {isChinese ? "导入ROM文件" : "Import ROM File"}</strong>
                <br />
                {isChinese 
                  ? "点击右上角的「+」按钮，从您的设备中选择ROM文件。" 
                  : "Tap the add button in the top right corner to select a ROM file from your device."}
              </Typography.Paragraph>
              
              <Typography.Paragraph>
                <strong>3. {isChinese ? "等待导入" : "Wait for Import"}</strong>
                <br />
                {isChinese 
                  ? "系统会自动处理并导入您选择的ROM文件。" 
                  : "The system will automatically process and import your selected ROM file."}
              </Typography.Paragraph>
              
              <Typography.Paragraph>
                <strong>4. {isChinese ? "开始游戏" : "Start Playing"}</strong>
                <br />
                {isChinese 
                  ? "导入完成后，返回主页即可看到并开始游戏。" 
                  : "Once imported, return to the home page to see and start your game."}
              </Typography.Paragraph>
            </Space>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Layout style={{ 
      minHeight: '100vh', 
      backgroundColor: '#f0f2f5',
      overflowX: 'hidden'
    }}>
      <Content style={{ 
        padding: '20px', 
        maxWidth: '1200px', 
        margin: '0 auto',
        width: '100%'
      }}>
        <Row 
          justify="space-between" 
          align="middle" 
          style={{ 
            marginBottom: '24px',
            flexWrap: 'nowrap',
            width: '100%'
          }}
        >
          <Title 
            level={2} 
            style={{ 
              margin: 0,
              fontSize: 'clamp(1.2rem, 1rem + 1vw, 1.8rem)',
              flexShrink: 1,
              whiteSpace: 'nowrap'
            }}
          >
            {isChinese ? '使用教程' : 'Tutorial'}
          </Title>
          <Button 
            type="text" 
            size="small" 
            onClick={toggleLanguage}
            icon={<GlobalOutlined />}
            style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '4px',
              flexShrink: 0,
              marginLeft: '12px',
              whiteSpace: 'nowrap'
            }}
          >
            {isChinese ? 'EN' : '中文'}
          </Button>
        </Row>

        <Row style={{
          padding: '12px 16px',
          background: '#FFF3E0',
          border: '1px solid #FFB74D',
          borderRadius: '6px',
          marginBottom: '24px',
          width: '100%'
        }}>
          <Col flex="auto">
            <Space align="start" style={{ width: '100%' }}>
              <ExclamationCircleOutlined style={{ 
                color: '#F57C00', 
                fontSize: '16px', 
                marginTop: '3px',
                flexShrink: 0
              }} />
              <Typography.Text style={{ 
                color: '#E65100',
                fontSize: '14px',
                lineHeight: '1.5',
                wordBreak: 'break-word'
              }}>
                {isChinese ? (
                  '请确保使用合法ROM，非法获取可能违反法律。'
                ) : (
                  'Please ensure legal ROM usage. Illegal acquisition may violate laws.'
                )}
              </Typography.Text>
            </Space>
          </Col>
        </Row>

        <Tabs defaultActiveKey="1" style={{ marginBottom: '40px' }}>
          <TabPane tab={isChinese ? "添加游戏" : "Add Game"} key="1">
            {renderVideoContent()}
          </TabPane>
          <TabPane tab={isChinese ? "金手指" : "Cheat Codes"} key="2">
            <Alert
              message={isChinese ? "即将推出" : "Coming Soon"}
              description={isChinese ? "该教程正在制作中，敬请期待" : "This tutorial is under production, stay tuned"}
              type="info"
              showIcon
              style={{ 
                maxWidth: '800px', 
                margin: '20px auto',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
              }}
            />
          </TabPane>
          <TabPane tab={isChinese ? "存档管理" : "Save Files"} key="3">
            <Alert
              message={isChinese ? "即将推出" : "Coming Soon"}
              description={isChinese ? "该教程正在制作中，敬请期待" : "This tutorial is under production, stay tuned"}
              type="info"
              showIcon
              style={{ 
                maxWidth: '800px', 
                margin: '20px auto',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
              }}
            />
          </TabPane>
          <TabPane tab={isChinese ? "屏幕旋转" : "Screen Rotation"} key="4">
            <Alert
              message={isChinese ? "即将推出" : "Coming Soon"}
              description={isChinese ? "该教程正在制作中，敬请期待" : "This tutorial is under production, stay tuned"}
              type="info"
              showIcon
              style={{ 
                maxWidth: '800px', 
                margin: '20px auto',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
              }}
            />
          </TabPane>
        </Tabs>

        <Row justify="center" style={{ marginTop: '40px' }}>
          <Col xs={24} sm={20} md={16}>
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <div style={{ textAlign: 'center' }}>
                <Space direction="vertical" size="small">
                  <Typography.Text>
                    {isChinese ? '如果您有任何疑问，请联系我们' : 'If you have any questions, please contact us'}
                  </Typography.Text>
                  <Button type="primary" onClick={() => window.location.href = '/contact'}>
                    {isChinese ? '联系我们' : 'Contact Us'}
                  </Button>
                </Space>
              </div>

              <div style={{ 
                fontSize: '12px', 
                color: '#666', 
                padding: '16px', 
                background: '#fff', 
                borderRadius: '6px',
                textAlign: 'center' 
              }}>
                {isChinese ? (
                  <>
                    使用本应用前，请确保您已同意我们的<a href="/policy/terms">服务条款</a>和<a href="/policy/privacy">隐私条款</a>。
                    如发现违规行为，请发送邮件至 <a href="mailto:report@hiappstore.com">report@hiappstore.com</a>
                  </>
                ) : (
                  <>
                    Before using this app, please ensure you agree to our <a href="/policy/terms">Terms of Service</a> and <a href="/policy/privacy">Privacy Policy</a>.
                    To report violations, please email <a href="mailto:report@hiappstore.com">report@hiappstore.com</a>
                  </>
                )}
              </div>
            </Space>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Tutorial; 