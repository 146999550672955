import React from 'react';
import { Layout, Typography, Row, Col, Card, Space } from 'antd';
import { BookOutlined, ToolOutlined, MessageOutlined, MailOutlined } from '@ant-design/icons';

function Support() {
    return (
        <Layout style={{ minHeight: '100vh', background: '#fff' }}>
            <Layout.Content style={{ padding: '64px 24px' }}>
                <Row justify="center">
                    <Col xs={24} md={20} lg={16}>
                        <Typography.Title level={1} style={{ textAlign: 'center', marginBottom: 48 }}>
                            Need Help?
                        </Typography.Title>
                        
                        <Row gutter={[24, 24]} justify="center">
                        <Col xs={24} md={8}>
                                <a href="/faq" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                    <Card hoverable>
                                        <Space direction="vertical" align="center" style={{ width: '100%' }}>
                                            <BookOutlined style={{ fontSize: 32 }} />
                                            <Typography.Title level={3}>User Guide</Typography.Title>
                                            <Typography.Text>
                                                Learn how to use the emulator
                                            </Typography.Text>
                                        </Space>
                                    </Card>
                                </a>
                            </Col>
                            
                            <Col xs={24} md={8}>
                                <a href="mailto:support@hiappstore.com" style={{ textDecoration: 'none' }}>
                                    <Card hoverable>
                                        <Space direction="vertical" align="center" style={{ width: '100%' }}>
                                            <MailOutlined style={{ fontSize: 32 }} />
                                            <Typography.Title level={3}>Email Support</Typography.Title>
                                            <Typography.Text>
                                                Contact us via email
                                            </Typography.Text>
                                            <Typography.Text type="secondary">
                                                support@hiappstore.com
                                            </Typography.Text>
                                        </Space>
                                    </Card>
                                </a>
                            </Col>

                          

                            {/* Troubleshooting - Temporarily Hidden
                            <Col xs={24} md={8}>
                                <Card hoverable>
                                    <Space direction="vertical" align="center" style={{ width: '100%' }}>
                                        <ToolOutlined style={{ fontSize: 32 }} />
                                        <Typography.Title level={3}>Troubleshooting</Typography.Title>
                                        <Typography.Text>
                                            Fix common emulation issues
                                        </Typography.Text>
                                    </Space>
                                </Card>
                            </Col>
                            */}

                            {/* Community Support - Temporarily Hidden
                            <Col xs={24} md={8}>
                                <Card hoverable>
                                    <Space direction="vertical" align="center" style={{ width: '100%' }}>
                                        <MessageOutlined style={{ fontSize: 32 }} />
                                        <Typography.Title level={3}>Community</Typography.Title>
                                        <Typography.Text>
                                            Join our gaming community
                                        </Typography.Text>
                                    </Space>
                                </Card>
                            </Col>
                            */}
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
}

export default Support; 