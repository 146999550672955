import React from 'react'

import appLogo from '../images/unds.png'

import appleStoreBadge from '../images/apple_store_badge.svg'
import googlePlayBadge from '../images/google_play_badge.png'

import coverImage from '../images/appIntro.png'


// section images
// import guitar from '../images/guitar.jpg'
// import event_phones from '../images/event_phones.jpeg'
// import foggy_blue from '../images/foggy_blue.jpg'
// import purple_phones from '../images/purple_phones.png'
// import purple_light from '../images/purple_light.jpg'
// import concert_classic from '../images/concert_classic.jpg'

import discordImage from '../images/discord.png'

export const initialState = {
    // when in dev, change appURL to local url
    // appURL: 'http://localhost:3000',
    // when in production, change appURL to real url
    appURL: 'https://unds.hiappstore.com',

    appLogo: appLogo,
    appName: 'UNDS',

    coverTitle: 'Retro Gaming Unleashed',
    coverText: 'One powerful emulator for NES, SNES, NDS, GBA, GBC and GB games.',
    appleStoreBadge: appleStoreBadge,
    appleStoreLink: 'https://apps.apple.com/us/app/unds-nds-gba-ultimate-emulator/id6737550290?l=en-us',

    coverImage: coverImage,
    coverImages: [],

    endorsementTitle: `Experience classic games on your favorite emulators`,
    endorsementText: `UNDS emulator supports various classic gaming consoles including NES, SNES, NDS, GBA, GBC, and GB, allowing you to relive your childhood gaming fun.`,
    endorsementDesc: 'All trademarks are property of Nintendo. Please ensure to use legally obtained ROMs.',
    endorsementList: [],

    // sectionList: [
    //     {
    //         'title': `Event Networking Made Easy`,
    //         'text': `Tired of shouting over the music to talk to your friends at concerts and events? Our app makes it easy to chat with others in real-time, so you can enjoy the experience without missing out on socializing.`,
    //         'image': guitar,
    //     },
    //     {
    //         'title': `Expand Your Event Community`,
    //         'text': `Meet like-minded people and share your excitement for the latest events and concerts.`,
    //         'image': event_phones,
    //     },
    //     {
    //         'title': `Enhance Event Experience`,
    //         'text': `Get instant access to a community of passionate event and concert-goers with our app! Chat with others before, during, and after the event to enhance your experience and create memories that last a lifetime.`,
    //         'image': foggy_blue,
    //     },
    //     {
    //         'title': `Chat with Attendees`,
    //         'text': `Don't let social anxiety get in the way of enjoying your favorite events and concerts! With our app, you can chat with others and make new friends in a safe, welcoming environment.`,
    //         'image': purple_phones,
    //     },
    //     {
    //         'title': `Discover New Events and Friends`,
    //         'text': `Our app is the perfect way to enhance your experience at events and concerts! Connect with others, share your thoughts and opinions, and discover new artists and events to love.`,
    //         'image': purple_light,
    //     },
    //     {
    //         'title': `Connect with Concert Fans`,
    //         'text': `Whether you're a seasoned concert-goer or a first-time attendee, our app is the perfect way to connect with others and make the most of your experience. Download now and start chatting!`,
    //         'image': concert_classic,
    //     },
    // ],
    sectionList: [],

    discordImage: discordImage,
    discordLink: 'https://discord.com/invite/aFQPYyAVDq',
}

const initialContext = {
    state: initialState,
    dispatch: () => null,
}

export const Context = React.createContext(initialContext)
