import { Typography, Row, Col } from 'antd'

function Payment() {
  const textStyle = {
    color: 'white'
  };

  return (
    <Typography component='div' style={{'height': '100vh', 'overflow': 'auto'}}>
        <Row type='flex' justify='center' style={{'margin': '20px'}}>
            <Col span={20}>
                <Typography.Title level={2} style={textStyle}>
                    PAYMENT POLICY
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    Last updated: November 20, 2024
                </Typography.Paragraph>

                <Typography.Title level={3} style={textStyle}>
                    AGREEMENT TO PAYMENT POLICY  
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    Thank you for choosing to use the services of UNDS ("we", "us", or "our") available through our mobile application on the Apple App Store (the "App"). This Payment Policy applies to your use of the App, including any purchases you make through the App. By accessing or using the App, you agree to be bound by this Payment Policy. If you disagree with any part of this policy, then you should not use the App.
                </Typography.Paragraph>

                <Typography.Title level={3} style={textStyle}>
                    PURCHASES
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    The App offer products and services for purchase ("In-App Purchases"). We use Apple's in-app purchase system for processing payments for In-App Purchases. Your purchase will be subject to Apple's applicable payment terms and conditions. You agree to pay all fees and applicable taxes incurred by you or anyone using your device.  
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    We do not collect or store any payment information from you when you make In-App Purchases. Apple collects payment information from you to process the transactions. For information about how Apple may use your payment information, please review Apple's privacy policy.
                </Typography.Paragraph>

                <Typography.Title level={3} style={textStyle}>
                    IN-APP PURCHASES
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    When you make an In-App Purchase, your purchase will be charged to your Apple account at the confirmation of purchase. The In-App Purchase will include applicable taxes where required. All In-App Purchases are final and non-refundable, except at our sole discretion and in accordance with the rules governing each In-App Purchase. Prices for In-App Purchases may change at any time.
                </Typography.Paragraph>

                <Typography.Title level={3} style={textStyle}>
                    SUBSCRIPTIONS
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    The App may offer automatically renewing subscriptions. If you purchase an auto-renewing subscription through the App, your Apple account will be billed continuously for the subscription until you cancel it. After your initial subscription commitment period, and again after any subsequent subscription period, your subscription will automatically continue for an additional equivalent period at the price you agreed to when subscribing. Your account will be charged for renewal within 24-hours prior to the end of the current period. You can manage and cancel your subscriptions by going to your account settings on the App Store after purchase. You will not receive a refund for the fees you already paid for your current subscription period and you will continue to receive access to the App until the end of your current subscription period.
                </Typography.Paragraph>

                <Typography.Title level={3} style={textStyle}>
                    REFUNDS
                </Typography.Title>
                
                <Typography.Paragraph style={textStyle}>
                    Generally, all charges for In-App Purchases are nonrefundable. If you believe there was an error in your purchase or have any other issues, you may submit a refund request directly to Apple.
                </Typography.Paragraph>
                
                <Typography.Paragraph style={textStyle}>
                    For In-App Purchases, if you believe there was an error in the purchase and you have not used or accessed the purchased item, you may submit a refund request to Apple. To request a refund, please visit <a href='https://getsupport.apple.com/' rel="noopener noreferrer" target='blank' style={{'color': 'blue'}}>https://getsupport.apple.com</a>.
                </Typography.Paragraph>

                <Typography.Title level={3} style={textStyle}>
                    CHANGES TO THIS PAYMENT POLICY  
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    We reserve the right to modify this Payment Policy at any time. If we make material changes to this policy, we will notify you by updating the date at the top of this policy. Your continued use of the App after any modification constitutes your acceptance of the new Payment Policy. If you do not agree to abide by this or any future Payment Policy, do not use or access (or continue to use or access) the App.
                </Typography.Paragraph>

                <Typography.Title level={3} style={textStyle}>
                    CONTACT US
                </Typography.Title>

                <Typography.Paragraph style={textStyle}>
                    If you have any questions about this Payment Policy or the App, please contact us at:
                </Typography.Paragraph>

                <Typography.Paragraph style={textStyle}>
                    UNDS<br/>
                    Email: unds@hiappstore.com
                </Typography.Paragraph>

            </Col>
        </Row>
    </Typography>
  )
}

export default Payment